<template>
  <div id="passcodeInput" class="d-flex security-code-input">
    <b-form-input v-for="(digit, i) in digits"
                  :key="'digit'+ i + digit"
                  class="security-code-digit-input"
                  :class="{ 'ml-4': i > 0 }"
                  :id="'security-code-input-' + i"
                  autocomplete="off"
                  :disabled="disabled"
                  :value="digit"
                  @keydown.delete.prevent="handleBackspace(i)"
                  @update="handleInput($event, i)">
    </b-form-input>
  </div>
</template>

<script>
export default {
  name: 'securityCodeInput',
  props: {
    value: {
      type: String
    },
    length: {
      type: Number,
      default: 6
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      digits: [],
      focusIndex: 0
    }
  },
  watch: {
    value: {
      immediate: true,
      handler (val) {
        const digits = []
        for (let i = 0; i < this.length; i++) {
          if (!val || !val[i] || val[i] === '-') {
            digits.push('')
          } else {
            digits.push(val[i])
          }
        }
        this.digits = digits
      }
    }
  },
  methods: {
    handleInput (val, i) {
      if (i === 0 && val.length === this.length) {
        this.$emit('input', val)
        this.focusIndex = this.length
        return
      }
      const digits = [...this.digits]
      if (val || val === '0') {
        digits[i] = val[0]
        this.focusIndex = i + 1
      } else {
        digits[i] = ''
        this.focusIndex = i
      }
      this.$emit('input', this.joinWithPlaceholders(digits))
    },
    handleBackspace (i) {
      const digits = [...this.digits]
      if (this.digits[i] === '') {
        digits[i - 1] = ''
        this.focusIndex = i - 1
        this.$emit('input', this.joinWithPlaceholders(digits))
      } else {
        digits[i] = ''
        this.focusIndex = i
        this.$emit('input', this.joinWithPlaceholders(digits))
      }
    },
    focus (i) {
      const el = document.getElementById('security-code-input-' + i)
      if (el) {
        el.focus()
      }
    },
    joinWithPlaceholders (arr) {
      return arr.map(d => d === '' ? '-' : d).join('')
    }
  },
  mounted () {
    this.focus(0)
  },
  updated () {
    this.focus(this.focusIndex)
  }
}
</script>

<style lang="scss">
.security-code-input {
  .security-code-digit-input {
    width: 0.6em;
    box-sizing: content-box;
    text-align: center;
    font-weight: bold;
    font-size: 150%;
  }
}
</style>
