<template>
  <div class="position-relative">
    <security-code-input :class="{'justify-content-center': alignment === 'center', 'opacity-half': loading}" v-model="securityCode" :disabled="loading"></security-code-input>
    <div v-if="errorMessage" class="text-danger mt-3">
      <fluency-icon type="warning"></fluency-icon>
      <span class="font-weight-bold">{{errorMessage}}</span>
    </div>
    <fluency-loader v-if="loading" dots class="absolute-center mt-2" :inline="true"></fluency-loader>
  </div>
</template>

<script>
import SecurityCodeInput from '@/components/common/forms/securityCodeInput.vue'
import FluencyLoader from 'core-ui/components/common/fluencyLoader.vue'

export default {
  name: 'verifyFactor',
  components: { SecurityCodeInput, FluencyLoader },
  props: {
    alignment: {
      type: String,
      default: 'left'
    },
    loading: {
      type: Boolean,
      default: false
    },
    errorMessage: {
      type: String
    }
  },
  data () {
    return {
      securityCode: ''
    }
  },
  watch: {
    securityCode (value) {
      if (value.replace(/\D/g).length === 6) {
        this.$emit('input', value)
      }
    }
  }
}
</script>
